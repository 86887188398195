<template>
  <el-dialog
    append-to-body
    width="560px"
    title="添加报告"
    :visible.sync="visible"
    :before-close="reset"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    center
    >
    <el-form
        class="staff-from"
        v-loading="loading"
        element-loading-text="加载中…"
        ref='elFormDom'
        label-width='130px'
        label-position='right'
        :model='form.data'
        :rules='form.rules'
        :size="subUnitSize"
      >
        <el-form-item label="配送日期" prop="delivery_date">
          <el-date-picker
            v-model="form.data.delivery_date"
            type="date"
            value-format="yyyy-MM-dd"
            format="yyyy-MM-dd"
            placeholder="选择日期"
            style="width:100%;">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="检测分类" prop="order_cate_id">
          <el-select v-model="form.data.order_cate_id" filterable placeholder="请选择" clearable style="width:100%;">
            <el-option
              v-for="item in orderCateArr"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="质检报告(1-3张)" prop="images">
          <el-upload
            action="/api/common/qiniu/upload"
            :limit="3"
            list-type="picture-card"
            class="imgWrap"
            :headers="{ token: token }"
            :on-preview="handlePictureCardPreview"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
            :on-remove="handleRemove"
            :file-list="fileList"
            accept="image/jpeg, image/png, image/gif"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
          <el-dialog :visible.sync="dialogVisibleUrl" append-to-body>
            <img width="100%" :src="dialogImageUrl" alt="" />
          </el-dialog>
        </el-form-item>
        <el-form-item label="供应商名称" prop="supplier_id">
          <el-select v-model="form.data.supplier_id" filterable id="bind_id" clearable placeholder="请选择" style="width:100%;">
            <el-option v-for="item in supplierArr" :label="item.name" :value="item.id" :key="item.id"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    
    <template #footer>
      <el-row type="flex" justify="center">
        <el-button type="primary" @click='confirm' :loading="loading">确定</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
  import { mixinTable } from '@/mixins/table.js'
  export default {
    name: 'PurchaseReportEdit',
    mixins: [mixinTable],
    data() {
      return {
        subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
        visible: false,
        isChange: false,
        loading: false,
        orderCateArr: [], // 食堂
        supplierArr: [], // 供应商
        form: {
          loading: false,
          data: {
            id: '',
            supplier_id: '',
            order_cate_id: '',
            delivery_date: '',
            images: '',
          },
          rules: {
            delivery_date: [{ required: true, message:'请选择', trigger: 'blur'}],
            order_cate_id: [{ required: true, message:'请选择', trigger: 'change'}],
            images: [{ required: true, message:'请上传', trigger: 'change'}],
          }
        },
        fileList: [],
        dialogImageUrl: '',
        dialogVisibleUrl: false,
       
      }
    },
    methods: {
      getDetail(row) {
        this.getOrderCateSel();
        this.getSupplier();
        if(!!row.id) {
          this.isChange = true;
          this.form.data = row;
        }
      },
      // 获取供应商 (启用中的供应商)
      getSupplier() {
        this.$http.get('/admin/supplier/list', {params:{status:1, page:1, count:10000}}).then(res => {
          if(res.code === 1) {
            this.supplierArr = res.data.list;
          }
        })
      },
      reset(done) {
        this.isChange = false;
        this.$refs.elFormDom.resetFields()
        this.fileList = [];
        this.$emit('refresh')
        done && done()
      },
      toggle(show) {
        this.visible = show
        !show && this.reset()
      },
      // 图片上传成功
      handleAvatarSuccess(res, file) {
        this.form.data.images = res.data.all_path_url;
        let strImg = {
          url: res.data.all_path_url,
          name: ''
        }
        this.fileList.push(strImg)
        this.$refs.elFormDom.clearValidate();
      },
      handleRemove(file, fileList) {
        if(this.fileList.length > 0) {
          this.fileList.forEach((v, i) => {
            if(file.uid === v.uid) {
              this.fileList.splice(i,1)
            }
          })
        }
      },
      // 图片上传之前
      beforeAvatarUpload(file) {
        const isLt20M = file.size / 1024 / 1024 < 20;
        if (!isLt20M) {
          this.$message.warning('上传图片大小不能超过 20MB!');
        }
        return isLt20M;
      },
      handlePictureCardPreview(file) {
        this.dialogImageUrl = file.url;
        this.dialogVisibleUrl = true;
      },
      confirm() {
        this.$refs.elFormDom.validate(valid => {
          if(valid) {
            let _params = {
              ...this.form.data,
              images: this.fileList.map(v => v.url).join("|") // 报告
            };
            console.log(_params)
            this.loading = true;
            this.$http.post('/admin/report/add', _params).then(res => {
              if(res.code === 1) {
                this.$message.success("操作成功！")
                this.toggle(false)
              }
            }).finally(() => {
              this.loading = false;
            })
          }
        })
      },
    }
  }
</script>
<style scoped>
.imgWrap {
  width: 300px;
  overflow: hidden;
  height: 80px;
}
.imgWrap ::v-deep .el-upload--picture-card {
  width: 80px !important;
  height: 80px !important;
  line-height: 80px !important;
}
.imgWrap ::v-deep .el-upload-list__item{
  width: 80px !important;
  height: 80px !important;
}
</style>
